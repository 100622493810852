import { store } from "../../redux/store";

export const setupInterceptors = (client) => {
  client.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const accessToken = state.user.token;

      if (config.delayed) {
        return new Promise((resolve) => setTimeout(() => resolve(config), 500));
      }

      // Check if the request URL matches any in the publicUrls
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};
