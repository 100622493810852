import { get, put, del } from "../../network/Config/apiService";

// Get All Users
const GetAllUsers = async () => {
  try {
    const res = await get("/admin/users");

    const usersData = res.data.data;

    return {
      result: "success",
      message: "Users data fetched Successfully",
      data: usersData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.response.data.message || "Could not fetch users data",
      data: [],
    };
  }
};

// Get User Details by UID
const GetUserDetails = async (uid) => {
  try {
    const res = await get(`/admin/user/${uid}`);

    const userData = res.data.data;

    return {
      result: "success",
      message: "User data fetched Successfully",
      data: userData,
    };
  } catch (err) {
    return {
      result: "error",
      message: err.response.data.message || "Could not fetch user data",
      data: null,
    };
  }
};

const UpdateUser = async (uid, data) => {
  try {
    await put(`/admin/user/${uid}`, data);

    return {
      result: "success",
      message: "User profile updated successfully",
      data: data,
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not update user profile",
      data: null,
    };
  }
};

const DeleteUser = async (uid) => {
  try {
    await del(`/admin/user/${uid}`);

    return {
      result: "success",
      message: "User deleted successfully",
    };
  } catch (err) {
    return {
      result: "error",
      message: err?.response?.data?.message || "Could not delete user",
    };
  }
};

export { GetAllUsers, GetUserDetails, UpdateUser, DeleteUser };
