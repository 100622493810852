const menu = [
  {
    icon: "dashlite",
    text: "SaaS Dashboard",
    link: "/",
  },
  {
    icon: "growth",
    text: "Analytics Dashboard",
    link: "/analytics",
  },
  {
    icon: "users",
    text: "User Manage",
    link: "/users",
  },
  {
    icon: "tranx",
    text: "Order & Invoice",
    link: "/history-payment",
  },
  {
    icon: "view-col",
    text: "Pricing Table",
    link: "/pricing-table",
  },
  {
    icon: "lock-alt",
    text: "Admin Users",
    link: "/admin-users",
  },
];
export default menu;
