import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Card,
  Modal,
  ModalBody,
  DropdownItem,
  Badge,
  Spinner,
} from "reactstrap";
import {
  Button,
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  PaginationComponent,
  Row,
  RSelect,
} from "../../../components/Component";
import { formatDate } from "../../../utils/Utils";
import { DeleteTransaction, GetAllTransactions, UpdateTransactionStatus } from "../../../network/apis/transactions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const PaymentHistory = () => {
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [detail, setDetail] = useState({});
  const [transactionData, setTransactionData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [selectedFilterStatus, setSelectedFilterStatus] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const deleteTransaction = async (paymentId) => {
    try {
      setIsDeleting(true);
      const res = await DeleteTransaction(paymentId);

      if (res.result === "success") {
        toast.success(res.message);
        fetchTransactions();
        setShowDeleteModal(false);
        setDetail({});
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? "An error occurred");
    } finally {
      setIsDeleting(false);
    }
  };

  const updateTransaction = async (paymentId, status) => {
    try {
      setIsUpdating(true);
      const res = await UpdateTransactionStatus(paymentId, status);

      if (res.result === "success") {
        toast.success(res.message);
        fetchTransactions();
        setShowUpdateModal(false);
        setDetail({});
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? "An error occurred");
    } finally {
      setIsUpdating(false);
    }
  };

  const sortingFunc = (params) => {
    setSortState(params);
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = transactionData.filter((item) => {
        return (
          item?.paymentId?.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item?.username?.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item?.planData?.title?.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...transactionData]);
    }
  }, [onSearchText, transactionData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // function to load detail data
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item.paymentId === id);
    setDetail(data[index]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [fetching, setFetching] = useState(false);

  const fetchTransactions = async () => {
    try {
      setFetching(true);
      const transactionsRes = await GetAllTransactions();

      if (transactionsRes.result === "success") {
        setTransactionData(transactionsRes.transactions);
        setData(transactionsRes.transactions);
      } else {
        toast.error(transactionsRes.message);
        setTransactionData([]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? "An error occurred");
      setTransactionData([]);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newData = transactionData;

    // Filter
    if (selectedFilterStatus) {
      newData = newData.filter((item) => item?.status?.toLowerCase() === selectedFilterStatus?.toLowerCase());
    }

    // Sorting
    if (sort) {
      if (sort === "asc") {
        newData = newData.sort((a, b) => a.date.localeCompare(b.name));
      } else if (sort === "dsc") {
        newData = newData.sort((a, b) => b.date.localeCompare(a.name));
      }
    }

    // Pagination
    const indexOfLastItem = currentPage * itemPerPage;
    const indexOfFirstItem = indexOfLastItem - itemPerPage;
    const currentItems = newData.slice(indexOfFirstItem, indexOfLastItem);
    setData(currentItems);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [currentPage, itemPerPage, sort, transactionData, selectedFilterStatus]);

  const formatTransactionPrice = (transaction) => {
    const currency = transaction?.planData?.currency;
    const amountToShow = transaction?.amountToShow ?? transaction.amount;

    return currency + " " + amountToShow;
  };

  return (
    <React.Fragment>
      <Head title="Payment History"></Head>
      {fetching ? (
        <Content>
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        </Content>
      ) : (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle page>Payment History</BlockTitle>
                <BlockDes className="text-soft">
                  <p>You have total {transactionData.length} transactions.</p>
                </BlockDes>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered card-stretch">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="card-title">
                      <h5 className="title">All transactions</h5>
                    </div>
                    <div className="card-tools me-n1">
                      <ul className="btn-toolbar">
                        <li>
                          <Button onClick={toggle} className="btn-icon search-toggle toggle-search">
                            <Icon name="search"></Icon>
                          </Button>
                        </li>
                        <li className="btn-toolbar-sep"></li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                              <Icon name="filter-alt"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end className="filter-wg dropdown-menu-lg" style={{ overflow: "visible" }}>
                              <div className="dropdown-head">
                                <span className="sub-title dropdown-title">Filter Users</span>
                              </div>
                              <div className="dropdown-body dropdown-body-rg">
                                <Row className="gx-6 gy-3">
                                  <Col size="12">
                                    <div className="form-group">
                                      <label className="overline-title overline-title-alt">Status</label>
                                      <RSelect
                                        options={[
                                          {
                                            label: "Paid",
                                            value: "Paid",
                                          },
                                          {
                                            label: "Expired",
                                            value: "Expired",
                                          },
                                          {
                                            label: "Pending",
                                            value: "Pending",
                                          },
                                          {
                                            label: "Deleted",
                                            value: "Deleted",
                                          },
                                        ]}
                                        placeholder="Any Status"
                                        onChange={(e) => {
                                          setSelectedFilterStatus(e.value);
                                        }}
                                        value={{
                                          label: selectedFilterStatus ?? "Any Status",
                                          value: selectedFilterStatus ?? "",
                                        }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="dropdown-foot between">
                                <a
                                  href="#reset"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setSelectedFilterStatus("");
                                  }}
                                  className="clickable"
                                >
                                  Reset Filter
                                </a>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                        <li>
                          <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                              <Icon name="setting"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-check">
                                <li>
                                  <span>Show</span>
                                </li>

                                <li className={itemPerPage === 10 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(10);
                                    }}
                                  >
                                    10
                                  </DropdownItem>
                                </li>
                                <li className={itemPerPage === 15 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(15);
                                    }}
                                  >
                                    15
                                  </DropdownItem>
                                </li>
                                <li className={itemPerPage === 30 ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setItemPerPage(30);
                                    }}
                                  >
                                    30
                                  </DropdownItem>
                                </li>
                              </ul>
                              <ul className="link-check">
                                <li>
                                  <span>Order</span>
                                </li>
                                <li className={sort === "dsc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("dsc");
                                      sortingFunc("dsc");
                                    }}
                                  >
                                    DESC
                                  </DropdownItem>
                                </li>
                                <li className={sort === "asc" ? "active" : ""}>
                                  <DropdownItem
                                    tag="a"
                                    href="#dropdownitem"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      setSortState("asc");
                                      sortingFunc("asc");
                                    }}
                                  >
                                    ASC
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </li>
                      </ul>
                    </div>
                    <div className={`card-search search-wrap ${!onSearch ? "active" : ""}`}>
                      <div className="search-content">
                        <Button
                          className="search-back btn-icon toggle-search"
                          onClick={() => {
                            setSearchText("");
                            toggle();
                          }}
                        >
                          <Icon name="arrow-left"></Icon>
                        </Button>
                        <input
                          type="text"
                          className="form-control border-transparent form-focus-none"
                          placeholder="Search by ID, User, Plan"
                          value={onSearchText}
                          onChange={(e) => onFilterChange(e)}
                        />
                        <Button className="search-submit btn-icon">
                          <Icon name="search"></Icon>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <table className="table table-tranx">
                    <thead>
                      <tr className="tb-tnx-head">
                        <th className="tb-tnx-id">
                          <span className="">ID</span>
                        </th>
                        <th className="tb-tnx-id">
                          <span className="">User</span>
                        </th>
                        <th className="tb-tnx-info">
                          <span className="tb-tnx-desc d-none d-sm-inline-block">
                            <span>Plan</span>
                          </span>
                          <span className="tb-tnx-date d-md-inline-block d-none">
                            <span className="">Date</span>
                          </span>
                        </th>
                        <th className="tb-tnx-amount is-alt">
                          <span className="tb-tnx-total">Total</span>
                          <span className="tb-tnx-status d-none d-md-inline-block">Status</span>
                        </th>
                        <th className="tb-tnx-action">
                          <span>&nbsp;</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0
                        ? data.map((item) => {
                            return (
                              <tr key={item.paymentId} className="tb-tnx-item">
                                <td className="tb-tnx-id">
                                  <a
                                    href="#ref"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      loadDetail(item.paymentId);
                                      setViewModal(true);
                                    }}
                                  >
                                    <span>{item.paymentId}</span>
                                  </a>
                                </td>
                                <td className="tb-tnx-id">
                                  <Link to={`/user/${item.userId}`} className="link link-primary">
                                    {item.userName}
                                  </Link>
                                </td>
                                <td className="tb-tnx-info">
                                  <div className="tb-tnx-desc">
                                    <span
                                      className="title"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item?.planData?.title} - {item?.planData?.planType}
                                    </span>
                                  </div>
                                  <div className="tb-tnx-date">
                                    <span className="date">{formatDate(item.date, "DD-MM-YYYY")}</span>
                                  </div>
                                </td>
                                <td className="tb-tnx-amount is-alt">
                                  <div className="tb-tnx-total">
                                    <span className="amount">{formatTransactionPrice(item)}</span>
                                  </div>
                                  <div className="tb-tnx-status">
                                    <Badge
                                      className="badge-dot"
                                      color={
                                        item.status === "paid"
                                          ? "success"
                                          : item.status === "pending"
                                          ? "warning"
                                          : "danger"
                                      }
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {item.status}
                                    </Badge>
                                  </div>
                                </td>
                                <td className="tb-tnx-action">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      tag="a"
                                      className="text-soft dropdown-toggle btn btn-icon btn-trigger"
                                    >
                                      <Icon name="more-h"></Icon>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                      <ul className="link-list-plain">
                                        <li
                                          onClick={() => {
                                            loadDetail(item.paymentId);
                                            setViewModal(true);
                                          }}
                                        >
                                          <DropdownItem
                                            tag="a"
                                            href="#view"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                            }}
                                          >
                                            View
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#update"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setShowUpdateModal(true);
                                              loadDetail(item.paymentId);
                                              setSelectedStatus({
                                                label: item.status,
                                                value: item.status,
                                              });
                                            }}
                                          >
                                            Update Status
                                          </DropdownItem>
                                        </li>
                                        <li>
                                          <DropdownItem
                                            tag="a"
                                            href="#delete"
                                            onClick={(ev) => {
                                              ev.preventDefault();
                                              setShowDeleteModal(true);
                                              loadDetail(item.paymentId);
                                            }}
                                          >
                                            Delete
                                          </DropdownItem>
                                        </li>
                                      </ul>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                <div className="card-inner">
                  {transactionData.length > 0 ? (
                    <PaginationComponent
                      noDown
                      itemPerPage={itemPerPage}
                      totalItems={transactionData.length}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  ) : (
                    <div className="text-center">
                      <span className="text-silent">No data found</span>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Block>

          {/* View Modal */}
          <Modal isOpen={viewModal} toggle={() => setViewModal(false)} className="modal-dialog-centered" size="lg">
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setViewModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="nk-modal-head">
                <h4 className="nk-modal-title title">
                  Transaction <small className="text-primary">#{detail?.paymentId}</small>
                </h4>
              </div>
              <div className="nk-tnx-details mt-sm-3">
                <Row className="gy-3">
                  <Col lg={6}>
                    <span className="sub-text">User Name</span>
                    <Link to={`/user/${detail.userId}`} className="caption-text">
                      {detail?.userName}
                    </Link>
                  </Col>

                  <Col lg={6}>
                    <span className="sub-text">User Email</span>
                    <span className="caption-text">{detail.email}</span>
                  </Col>

                  <Col lg={6}>
                    <span className="sub-text">Plan Name</span>
                    <span className="caption-text">{detail?.planData?.title}</span>
                  </Col>
                  <Col lg={6}>
                    <span className="sub-text">Plan Duration</span>
                    <span className="caption-text" style={{ textTransform: "capitalize" }}>
                      {detail?.planData?.duration} {detail?.planData?.duration > 1 ? "Months" : "Month"}
                    </span>
                  </Col>

                  <Col lg={6}>
                    <span className="sub-text">Amount</span>
                    <span className="caption-text">{formatTransactionPrice(detail)}</span>
                  </Col>

                  <Col lg={6}>
                    <span className="sub-text">Status</span>
                    <Badge
                      color={detail.status === "paid" ? "success" : detail.status === "pending" ? "warning" : "danger"}
                      size="md"
                      style={{ textTransform: "capitalize" }}
                    >
                      {detail.status}
                    </Badge>
                  </Col>
                </Row>
              </div>
            </ModalBody>
          </Modal>

          {/* Delete Modal */}
          <Modal
            isOpen={showDeleteModal}
            toggle={() => setShowDeleteModal(false)}
            className="modal-dialog-centered"
            size="md"
          >
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowDeleteModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="nk-modal-head">
                <h4 className="nk-modal-title title">
                  Are you sure you want to delete this transaction{" "}
                  <span className="text-primary">{detail?.paymentId}</span>?
                </h4>
              </div>

              <div className="nk-modal-head mt-4">
                <Button
                  className="btn btn-primary"
                  disabled={isDeleting}
                  onClick={() => {
                    deleteTransaction(detail?.paymentId);
                  }}
                >
                  {isDeleting ? <Spinner size="sm" color="light" /> : "Delete Transaction"}
                </Button>

                <Button
                  onClick={() => setShowDeleteModal(false)}
                  className="btn btn-outline-secondary mx-2"
                  disabled={isDeleting}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>

          {/* Update Status Modal */}
          <Modal
            isOpen={showUpdateModal}
            toggle={() => setShowUpdateModal(false)}
            className="modal-dialog-centered"
            size="md"
          >
            <ModalBody>
              <a
                href="#cancel"
                onClick={(ev) => {
                  ev.preventDefault();
                  setShowUpdateModal(false);
                }}
                className="close"
              >
                <Icon name="cross-sm"></Icon>
              </a>
              <div className="nk-modal-head">
                <h4 className="nk-modal-title title">
                  Update Transaction Status for <span className="text-primary">{detail?.paymentId}</span>
                </h4>
              </div>

              <div className="nk-modal-head mt-4">
                <RSelect
                  options={[
                    {
                      label: "Paid",
                      value: "paid",
                    },
                    {
                      label: "Pending",
                      value: "pending",
                    },
                    {
                      label: "Deleted",
                      value: "deleted",
                    },
                    {
                      label: "Canceled",
                      value: "canceled",
                    },
                  ]}
                  value={
                    !selectedStatus
                      ? {
                          label: "Select Status",
                          value: "",
                        }
                      : selectedStatus
                  }
                  onChange={(e) => {
                    setSelectedStatus(e);
                  }}
                />
              </div>

              <div className="nk-modal-head mt-4">
                <Button
                  className="btn btn-primary"
                  disabled={isUpdating}
                  onClick={() => {
                    updateTransaction(detail?.paymentId, selectedStatus?.value);
                  }}
                >
                  {isUpdating ? <Spinner size="sm" color="light" /> : "Update Status"}
                </Button>

                <Button
                  onClick={() => setShowUpdateModal(false)}
                  className="btn btn-outline-secondary mx-2"
                  disabled={isUpdating}
                >
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Content>
      )}
    </React.Fragment>
  );
};

export default PaymentHistory;
