import React, { useEffect, useState } from "react";
import { Form, Modal, ModalBody, Spinner } from "reactstrap";
import { Button, Col, Icon, RSelect } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { GetAllPricePlans } from "../../../network/apis/pricePlan";
import { CreateTransaction } from "../../../network/apis/transactions";

function AddInvoiceModel({ modal, setModal, user, refetchData }) {
  const { handleSubmit } = useForm();

  const [requestingPaymentLink, setRequestingPaymentLink] = useState(false);

  // submit function to add a new item
  const onFormSubmit = async () => {
    await AddInvoice();
  };

  const onFormReset = () => {
    setSelectedPlan(null);
    setSelectedPlanType(null);
    setSelectedCurrency(null);
    setNotifyUser(false);
    setModal({ add: false });
  };

  const AddInvoice = async () => {
    try {
      setRequestingPaymentLink(true);
      // Generate unique payment id
      const paymentId = Math.random().toString(36).substring(7) + "-" + Date.now();

      const res = await CreateTransaction({
        amount: selectedPlanType?.value === "monthly" ? selectedPlan.monthlyTomans : selectedPlan.yearlyTomans,
        description: selectedPlan.title,
        date: new Date().toISOString(),
        email: user.email,
        paymentId: paymentId,
        phone: user.phone,
        planData: {
          ...selectedPlan,
          planType: selectedPlanType.value,
          currency: selectedCurrency.value,
        },
        uid: user.uid,
        userName: user.name,
      });

      if (res.status === "success") {
        setRequestingPaymentLink(false);
        toast.success("Invoice added successfully");
        console.log(typeof refetchData);
        if (refetchData && typeof refetchData === "function") {
          refetchData();
        }
        onFormReset();
      } else {
        setRequestingPaymentLink(false);
        toast.error(res?.message ?? "Something went wrong. Please try again later.");
      }
    } catch (error) {
      setRequestingPaymentLink(false);
      toast.error(error?.message ?? "Something went wrong. Please try again later.");
    }
  };

  const [fetchingPlans, setFetchingPlans] = useState(true);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPlanType, setSelectedPlanType] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [notifyUser, setNotifyUser] = useState(false);

  const fetchPlans = async () => {
    try {
      setFetchingPlans(true);
      const res = await GetAllPricePlans();
      if (res.status === "success") {
        setPlans(res.data);
      } else {
        toast.error(res.message);
        setPlans([]);
      }
    } catch (error) {
      setPlans([]);
      toast.error(error?.message ?? "Something went wrong. Please try again later.");
    } finally {
      setFetchingPlans(false);
    }
  };

  useEffect(() => {
    if (modal.add) {
      fetchPlans();
    }
  }, [modal.add]);

  return (
    <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            onFormReset();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        {fetchingPlans ? (
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        ) : (
          <div className="p-2">
            <h5 className="title">
              Add New Invoice
              <span className="fs-6 fw-normal text-muted d-block mt-1">Fill in the details to add a new invoice</span>
            </h5>
            <div className="mt-4">
              <Form className="row gy-4 mt-4" onSubmit={handleSubmit(onFormSubmit)} onReset={onFormReset}>
                <Col md="12">
                  <div className="form-group">
                    <label className="form-label">Plan</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={plans.map((plan) => {
                          return {
                            label: plan.title,
                            value: plan.id,
                          };
                        })}
                        value={{
                          label: selectedPlan?.title ?? "Select a plan",
                          value: selectedPlan?.id ?? "Select a plan",
                        }}
                        onChange={(e) => {
                          const plan = plans.find((plan) => plan.id === e.value);
                          setSelectedPlan(plan);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Plan Type</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={[
                          {
                            label: "Monthly",
                            value: "monthly",
                          },
                          {
                            label: "Yearly",
                            value: "yearly",
                          },
                        ]}
                        value={
                          selectedPlanType ?? {
                            label: "Select a plan type",
                            value: "Select a plan type",
                          }
                        }
                        onChange={(e) => {
                          setSelectedPlanType(e);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Currency</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={[
                          {
                            label: "USD",
                            value: "USD",
                          },
                          {
                            label: "Toman (IRR)",
                            value: "IRR",
                          },
                        ]}
                        value={
                          selectedCurrency ?? {
                            label: "Select currency",
                            value: "Select currency",
                          }
                        }
                        onChange={(e) => {
                          setSelectedCurrency(e);
                        }}
                      />
                    </div>
                  </div>
                </Col>

                <Col md="6">
                  <div className="form-group">
                    <label className="form-label flex" htmlFor="notifyUser">
                      <input
                        type="checkbox"
                        name="notifyUser"
                        id="notifyUser"
                        checked={notifyUser}
                        onChange={(e) => setNotifyUser(e.target.checked)}
                      />
                      <span className="form-label-text mx-1">Notify User by Email</span>
                    </label>
                  </div>
                </Col>

                <Col size="12">
                  <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                      <Button
                        color="primary"
                        size="md"
                        type="submit"
                        disabled={requestingPaymentLink || !selectedPlan || !selectedPlanType || !selectedCurrency}
                      >
                        {requestingPaymentLink ? "Adding..." : "Add Invoice"}
                      </Button>
                    </li>
                    <li>
                      <a
                        href="#cancel"
                        onClick={(ev) => {
                          ev.preventDefault();
                          onFormReset();
                        }}
                        className="link link-light"
                      >
                        Cancel
                      </a>
                    </li>
                  </ul>
                </Col>
              </Form>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
}

export default AddInvoiceModel;
