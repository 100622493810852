import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { Badge, Modal, ModalBody, Spinner } from "reactstrap";
import {
  BlockBetween,
  BlockDes,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Col,
  Row,
  Icon,
  Button,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { DeletePricePlan, GetAllPricePlans } from "../../../network/apis/pricePlan";
import { toast } from "react-toastify";
import AddPricingPlanModel from "./AddPricingPlanModel";
import EditPricingPlanModel from "./EditPricingPlanModel";

const PricingTable = () => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [modal, setModal] = useState({ add: false, edit: false, delete: false });
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [deletingPlan, setDeletingPlan] = useState(false);

  const fetchPricingPlans = async () => {
    try {
      setFetchingData(true);
      const res = await GetAllPricePlans();

      if (res.status === "success") {
        setPricingPlans(res.data);
        console.log("Here");
      } else {
        toast.error(res.message);
        setPricingPlans([]);
      }
    } catch (error) {
      toast.error(error.message);
      setPricingPlans([]);
    } finally {
      setFetchingData(false);
    }
  };

  const deletePlan = async (id) => {
    try {
      setDeletingPlan(true);
      const res = await DeletePricePlan(id);
      if (res.status === "success") {
        toast.success(res.message);
        fetchPricingPlans();
        setModal({ delete: false });
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDeletingPlan(false);
    }
  };

  useEffect(() => {
    fetchPricingPlans();
  }, []);

  return (
    <React.Fragment>
      <Head title="Pricing Table"></Head>

      {fetchingData ? (
        <Content>
          <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5">
            <Spinner size="lg" color="primary" />
          </section>
        </Content>
      ) : (
        <Content>
          <Block size="lg">
            <BlockHead>
              <BlockBetween className="g-3">
                <BlockContent>
                  <BlockTitle>Pricing Table</BlockTitle>
                  <BlockDes className="text-soft">
                    <p>Manage all pricing plans from here.</p>
                  </BlockDes>
                </BlockContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  style={{
                    marginTop: "-6px",
                  }}
                  onClick={() => {
                    setModal({
                      add: true,
                      edit: false,
                    });
                  }}
                >
                  <Icon name="plus"></Icon>
                  <span>Add New</span>
                </Button>
              </BlockBetween>
            </BlockHead>
            <Row className="g-gs">
              {pricingPlans.map((item) => {
                return (
                  <Col md={6} key={item.id}>
                    <Card className={`card-bordered pricing text-center ${item.tags ? "recommend" : ""}`}>
                      <section
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                          position: "absolute",
                          top: "1px",
                          right: "1px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          alignItems: "end",
                          gap: "10px",
                          padding: "10px",
                        }}
                      >
                        {item.isPopular && (
                          <Badge
                            color="primary"
                            className="pricing-badge"
                            style={{
                              position: "static",
                            }}
                          >
                            Popular
                          </Badge>
                        )}
                        {item.isForFarsiUsers && (
                          <Badge
                            color="primary"
                            className="pricing-badge"
                            style={{
                              position: "static",
                            }}
                          >
                            For Farsi Users
                          </Badge>
                        )}
                      </section>

                      <div className="pricing-body">
                        <div className="pricing-title">
                          <h5 className="title">{item.title}</h5>
                          <span className="sub-text">{item.description}</span>
                        </div>
                        <section>
                          <h6 className="pricing-amount-title">Pricing Without Bot</h6>
                          <div className="pricing-amount-text-section">
                            <p className="pricing-amount-text">
                              <b>USD:</b> <span>${item.withoutBotUsdPrice}</span>
                            </p>

                            <p className="pricing-amount-text">
                              <b>Tomans:</b> <span>IRR{item.withoutBotTomansPrice}</span>
                            </p>
                          </div>
                        </section>

                        <section className="mt-3">
                          <h6 className="pricing-amount-title">Pricing With Bot</h6>
                          <div className="pricing-amount-text-section">
                            <p className="pricing-amount-text">
                              <b>USD:</b> <span>${item.withBotUsdPrice}</span>
                            </p>

                            <p className="pricing-amount-text">
                              <b>Tomans:</b> <span>IRR {item.withBotTomansPrice}</span>
                            </p>
                          </div>
                        </section>

                        <ul className="my-3">
                          <h6 className="pricing-amount-title">Features</h6>
                          {item?.features?.map((feature, index) => {
                            return (
                              <li
                                key={index}
                                className=""
                                style={{
                                  margin: "0",
                                  marginTop: "2px",
                                  marginBottom: "2px",
                                }}
                              >
                                {feature}
                              </li>
                            );
                          })}

                          {item?.extraFeatures?.length > 0 && (
                            <>
                              <h6 className="pricing-amount-title mt-3">Extra Features (With Bot)</h6>
                              {item?.extraFeatures?.map((feature, index) => {
                                return (
                                  <li
                                    key={index}
                                    className=""
                                    style={{
                                      margin: "0",
                                      marginTop: "2px",
                                      marginBottom: "2px",
                                    }}
                                  >
                                    {feature}
                                  </li>
                                );
                              })}
                            </>
                          )}
                        </ul>

                        <div className="pricing-action">
                          <Button
                            color="primary"
                            className="mx-1"
                            onClick={() => {
                              setSelectedPlan(item);
                              setModal({
                                add: false,
                                edit: true,
                              });
                            }}
                          >
                            <Icon name="edit"></Icon>
                            <span>Update</span>
                          </Button>

                          <Button
                            className="mx-1 btn btn-outline-danger "
                            onClick={() => {
                              setSelectedPlan(item);
                              setModal({
                                add: false,
                                edit: false,
                                delete: true,
                              });
                            }}
                          >
                            <Icon name="trash-fill"></Icon>
                            <span>Delete</span>
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Block>

          <AddPricingPlanModel
            modal={modal}
            setModal={setModal}
            refetchData={() => {
              fetchPricingPlans();
            }}
          />

          <EditPricingPlanModel
            modal={modal}
            setModal={setModal}
            refetchData={() => {
              fetchPricingPlans();
            }}
            selectedPlan={selectedPlan}
          />

          <Modal
            isOpen={modal.delete}
            toggle={() => setModal({ delete: false })}
            className="modal-dialog-centered"
            size="sm"
          >
            <ModalBody>
              <h4 className="text-center">Are you sure you want to delete this plan?</h4>
              <Block className="text-center mt-4">
                <Button
                  color="danger"
                  className="mx-1"
                  onClick={() => {
                    deletePlan(selectedPlan.id);
                  }}
                  disabled={deletingPlan}
                >
                  {deletingPlan ? "Deleting..." : "Delete"}
                </Button>
                <Button
                  color="light"
                  className="mx-1"
                  onClick={() => {
                    setModal({ delete: false });
                  }}
                >
                  Cancel
                </Button>
              </Block>
            </ModalBody>
          </Modal>
        </Content>
      )}
    </React.Fragment>
  );
};

export default PricingTable;
