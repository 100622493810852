import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Form, Spinner } from "reactstrap";
import { Icon, Col, Button, RSelect } from "../../../components/Component";
import { useForm } from "react-hook-form";
import { SendPasswordResetEmail } from "../../../network/apis/profile";
import { toast } from "react-toastify";

const EditModal = ({ modal, closeModal, onSubmit, formData, setFormData, filterStatus, updating = false }) => {
  useEffect(() => {
    reset(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [sendingResetPassword, setSendingResetPassword] = useState(false);

  const resetPassword = async (email) => {
    try {
      setSendingResetPassword(true);
      // Call the ResetPassword function from the auth.js file in the firebase/functions folder
      const res = await SendPasswordResetEmail(email);

      setSendingResetPassword(false);
      if (res.result === "error") {
        toast.error(res.message);
      } else {
        toast.success("Reset link sent to email address");
      }
    } catch (error) {
      setSendingResetPassword(false);
      toast.error(error?.message ?? "Cannot send reset link");
    }
  };

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">Update User</h5>
          <div className="mt-4">
            <Form className="row gy-4" onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name", { required: "This field is required" })}
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    placeholder="Enter name"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("email", {
                      required: "This field is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    placeholder="Enter email"
                  />
                  {errors.email && <span className="invalid">{errors.email.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Phone</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("phone", {})}
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  />
                  {errors.phone && <span className="invalid">{errors.phone.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Trading Platform Id</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("tradingPlatformId", {})}
                    value={formData.tradingPlatformId}
                    onChange={(e) => setFormData({ ...formData, tradingPlatformId: e.target.value })}
                  />
                  {errors.tradingPlatformId && <span className="invalid">{errors.tradingPlatformId.message}</span>}
                </div>
              </Col>
              <Col md="12">
                <div className="form-group">
                  <label className="form-label">Status</label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={filterStatus}
                      value={{
                        value: formData.status,
                        label: formData.status,
                      }}
                      onChange={(e) => setFormData({ ...formData, status: e.value })}
                    />
                  </div>
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit" disabled={updating || sendingResetPassword}>
                      {updating ? (
                        <span>
                          <Spinner size="sm" color="light" />
                        </span>
                      ) : (
                        "Update User"
                      )}
                    </Button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      disabled={updating || sendingResetPassword}
                      className="link link-light"
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </Col>
              <Col size="12">
                <Button
                  outline={true}
                  color="primary"
                  size="md"
                  type="button"
                  disabled={updating}
                  onClick={() => resetPassword(formData.email)}
                >
                  {sendingResetPassword ? (
                    <span>
                      <Spinner size="sm" color="light" />
                    </span>
                  ) : (
                    "Send Reset Password Email"
                  )}
                </Button>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default EditModal;
